import React, { useState, useEffect, useRef } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import customStyle from './custom-style.css';

interface Option {
    value: string | number;
    label: string;
}

interface CustomSelectProps {
    options: Option[];
    imgSrc?: string;
    label?: string;
    className?: string;
    onChange: (selected: string | number | (string | number)[]) => void;
    multiSelect?: boolean;
    defaultValue?: string | number | (string | number)[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    options,
    imgSrc,
    label,
    className,
    onChange,
    multiSelect = false,
}) => {
    const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(prev => !prev);
    const t = usePhraseTranslater();
    const handleSelect = (value: string | number) => {
        let newSelection;

        if (multiSelect) {
            newSelection = selectedOptions.includes(value)
                ? selectedOptions.filter(v => v !== value)
                : [...selectedOptions, value]; // Add selection
        } else {
            newSelection = selectedOptions.includes(value) ? [] : [value];
            setIsOpen(false);
        }

        setSelectedOptions(newSelection);
        onChange(multiSelect ? newSelection : newSelection[0] || '');
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    let renderOption = (
        <span className={customStyle.selectedOption}>
            {options.find(opt => opt.value === selectedOptions[0])?.label}
        </span>
    );

    if (multiSelect) {
        renderOption = (
            <>
                {selectedOptions.map(val => (
                    <span key={val} className={customStyle.selectedOption}>
                        {options.find(opt => opt.value === val)?.label}
                    </span>
                ))}
            </>
        );
    }

    return (
        <div className={`${customStyle.selectBox} ${className}`} ref={dropdownRef}>
            {label && <span>{label}</span>}
            <AccessibleButton
                tag="div"
                action={() => toggleDropdown()}
                className={customStyle.selectedBox}
            >
                {selectedOptions.length > 0 ? (
                    { renderOption }
                ) : (
                    <span className={customStyle.placeholder}>{t('Search by Tag')}</span>
                )}
            </AccessibleButton>

            {isOpen && (
                <div className={customStyle.dropdownMenu}>
                    {options.map(option => (
                        <AccessibleButton
                            tag="div"
                            action={() => handleSelect(option.value)}
                            className={classes(customStyle.dropdownItem, {
                                [customStyle.selected]: selectedOptions.includes(option.value),
                            })}
                        >
                            {imgSrc && <img src={imgSrc} alt={option.label} className={customStyle.optionImg} />}
                            {option.label}
                        </AccessibleButton>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
