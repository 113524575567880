import React, { Dispatch, SetStateAction } from 'react';
import CustomSelect from './custome-select';

interface SelectBoxProps {
    id?: string;
    name?: string;
    label?: string;
    options: { value: string | number; label: string }[];
    value: string | number;
    onChange: Dispatch<SetStateAction<string>>;
    disabled?: boolean;
    className?: string;
    defaultValue?: string | number | (string | number)[];
}

const SelectBox: React.FC<SelectBoxProps> = ({
    label,
    options,
    onChange,
    className = '',
    defaultValue,
}) => {
    const handleSelection = (selected: string | number | (string | number)[]) => {
        onChange(selected.toString());
    };

    return (
        <>
            <CustomSelect
                defaultValue={defaultValue}
                options={options}
                label={label}
                className={className}
                onChange={handleSelection}
            />
        </>
    );
};

export default SelectBox;
