import React from 'react';
import { useQuery } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { GET_BLOG_LIST_LAYOUT } from 'graphql/blog/blogs-layout';
import { LoadingImage } from 'ui/component/loading-image';
import { BlogLayoutData } from 'ui/util/type-helper';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import HeroBanner from './hero-banner/hero-banner';
import FeaturedBlog from './featured-blog/featured-blog';
import BlogListingCover from './blog-listing/blog-listing-cover';

import styles from './style.css';

const BlogContent: React.FC = () => {
    const storeConfig = useConfig();
    const storeId = storeConfig.store_config.current_store.id;
    const pageTitle = storeConfig?.design_config?.default_page_title ?? '';

    const {
        loading, data,
    } = useQuery<BlogLayoutData>(GET_BLOG_LIST_LAYOUT, {
        variables: {
            storeId,
        },
    });

    const t = usePhraseTranslater();

    if (loading) {
        return (
            <div className={styles.smallLoadingImage}>
                <LoadingImage />
            </div>
        );
    }

    const updateMetaTag = (name: string, content: string) => {
        let metaTag = document.querySelector(`meta[name='${name}']`);
        if (metaTag) {
            metaTag.setAttribute('content', content);
        } else {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
        }
    };

    if (data?.getBlogListLayout?.description) {
        updateMetaTag('description', data?.getBlogListLayout?.description);
    }
    if (data?.getBlogListLayout?.title) {
        updateMetaTag('title', data?.getBlogListLayout?.title);
    }

    const postCategory = data?.getBlogListLayout?.categories;
    const tags = data?.getBlogListLayout?.tags;
    const featuredPost = data?.getBlogListLayout?.featured_post;
    const bannerImage = data?.getBlogListLayout?.banner_image;
    const blogTitle = data?.getBlogListLayout?.title;
    const blogDescription = data?.getBlogListLayout?.description;

    return (
        <>
            <DocumentTitle>{`${blogTitle} | ${pageTitle}`}</DocumentTitle>
            {bannerImage && <HeroBanner description={blogDescription} imageUrl={bannerImage} altText={t(`${pageTitle} blog listing`)} />}
            <div className={styles.content}>
                {featuredPost && featuredPost.store_id && <FeaturedBlog featuredPost={featuredPost} />}
                <BlogListingCover tags={tags} filters={postCategory} storeId={storeId} />
            </div>
        </>
    );
};

export default BlogContent;
