import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import Tag from 'ui/component/tag/tag';
import navigateSideArrow from 'assets/images/navigate-side-arrow.svg';
import SocialShare from 'ui/component/social-share/social-share';

import styles from './style.css';

interface CardProps {
    imageUrl: string;
    imageAlt: string;
    author: string;
    date: string;
    title: string;
    description: string;
    // eslint-disable-next-line camelcase
    url_key_path?: string;
    // eslint-disable-next-line camelcase
    tags?: { name: string; tag_id: number }[];
    // eslint-disable-next-line camelcase
    fb_share_link?: string;
    // eslint-disable-next-line camelcase
    twitter_share_link?: string;
    additionalInfo?: string;
    layout?: 'flex-row' | 'flex-col';
    className?: string;
    cardClassName?: string;
    isFeatured?: boolean;
    isListingPage?: boolean;
    displayIcon?: boolean;
    mobileImageUrl?: string;
    onTagClick?: (tagId: (number | null)) => void;
}

const Card: React.FC<CardProps> = ({
    imageUrl,
    imageAlt,
    author,
    date,
    title,
    description,
    // eslint-disable-next-line camelcase
    url_key_path: urlKeyPath,
    tags,
    additionalInfo,
    layout = 'flex-col',
    className = '',
    cardClassName = '',
    isFeatured = false,
    isListingPage = false,
    displayIcon = false,
    fb_share_link: fbShareLink = '',
    twitter_share_link: twitterShareLink = '',
    mobileImageUrl,
    onTagClick,
}) => (
    <div className={classes(styles.cardCnt, {
        [styles.flexColDisplay]: layout === 'flex-col',
        [styles.flexRowDisplay]: layout !== 'flex-col',
    }, className)}
    >
        <div className={classes(styles.cardCnt, {
            [styles.flexColDisplay]: layout === 'flex-col',
            [styles.flexRowDisplay]: layout !== 'flex-col',
            [cardClassName]: cardClassName,
        })}
        >
            <div className={classes(styles.cardTopCnt, {
                [styles.cardTopWCnt]: isFeatured,
                [styles.widthFullCnt]: !isFeatured,
                [styles.cardListImagCnt]: !isFeatured,
            })}
            >
                <AccessibleButton
                    tag="a"
                    action={() => {
                        if (urlKeyPath) {
                            window.location.assign(urlKeyPath);
                        }
                    }}
                    className={styles.linkCnt}
                >
                    <img
                        src={imageUrl}
                        alt={imageAlt}
                        className={classes(styles.imgCnt, {
                            [styles.desktopImageEle]: mobileImageUrl,
                            [styles.imgElementCss]: !isFeatured,
                            [styles.imgElementIsFeatured]: isFeatured,
                        })}
                    />
                    {mobileImageUrl && (
                        <img
                            src={imageUrl}
                            alt={imageAlt}
                            className={classes(styles.imgCnt, styles.mobileImageEle, {
                                [styles.imgElementCss]: !isFeatured,
                                [styles.imgElementIsFeatured]: isFeatured,
                            })}
                        />
                    )}
                </AccessibleButton>
            </div>
            <div className={classes(styles.cardBottomCnt, {
                [styles.cardBottomWCnt]: isFeatured,
                [styles.widthFullCnt]: !isFeatured,
                [styles.cardListContentCnt]: !isFeatured,
            })}
            >
                <div className={styles.authorCnt}>
                    <span>{author}</span>
                    {' | '}
                    <span>{date}</span>
                </div>
                <div className={classes(styles.cardTitleCnt, {
                    [styles.cardTitleListingPage]: isListingPage,
                    [styles.cardTitlePage]: !isListingPage,
                })}
                >
                    <AccessibleButton
                        tag="a"
                        action={() => {
                            if (urlKeyPath) {
                                window.location.assign(urlKeyPath);
                            }
                        }}
                        className={styles.linkCnt}
                    >
                        <p>
                            {title}
                        </p>
                    </AccessibleButton>
                </div>
                <div className={classes(styles.descriptionCnt, {
                    [styles.descriptionOptionStyle]: additionalInfo,
                    [styles.descriptionListingPage]: isListingPage,
                    [styles.descriptionPage]: !isListingPage,
                })}
                >
                    {/* eslint-disable react/no-danger */}
                    <div
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
                <div className={styles.tagShareCnt}>
                    <div className={styles.tagMainCnt}>
                        {tags && tags.map(tag => (
                            <Tag key={tag.tag_id} onClick={onTagClick} name={tag.name} id={tag.tag_id} />
                        ))}
                    </div>
                    <SocialShare fbShareLink={fbShareLink} twitterShareLink={twitterShareLink} />
                </div>
                {additionalInfo && <div className="cardAdditionalInfo"><p>{additionalInfo}</p></div>}
            </div>
            {urlKeyPath && displayIcon && (
                <div className={styles.navigateArrowCnt}>
                    <AccessibleButton
                        tag="a"
                        action={() => {
                            if (urlKeyPath) {
                                window.location.assign(urlKeyPath);
                            }
                        }}
                        className={styles.linkCnt}
                    >
                        <img src={navigateSideArrow} alt={title} className={styles.navigateArrowImgElement} />
                    </AccessibleButton>
                </div>
            )}
        </div>
    </div>
);

export default Card;
