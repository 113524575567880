import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import BlogContent from './blog-content';

const BlogPage: React.FC = () => (
    <ApolloProvider client={GraphQlClient}>
        <BlogContent />
    </ApolloProvider>
);

export default BlogPage;
